import { DistributorTypes } from "types/reports"
import OrchardIcon from "../../../assets/images/upload/Orchard.svg"
import IngroveesIcon from "../../../assets/images/upload/Ingrovees.svg"
import OneRpmIcon from "../../../assets/images/upload/OneRpm.png"
import DistrokidIcon from "../../../assets/images/upload/Distrokid.png"
import BelieveIcon from "../../../assets/images/upload/BelieveIcon.png"
import TuneCoreIcon from "../../../assets/images/upload/distributors/tune-core.jpeg"
import IdolIcon from "../../../assets/images/upload/distributors/idol.svg"
import FugaIcon from "../../../assets/images/upload/distributors/Fuga.png"
import RevelatorIcon from "../../../assets/images/upload/distributors/relevator.png"
import StemInIcon from "../../../assets/images/upload/distributors/stem-in.png"
import SymphonicIcon from "../../../assets/images/upload/distributors/symphonic.png"
import AltafonteIcon from "../../../assets/images/upload/distributors/altafonte.jpeg"
import AmuseIcon from "../../../assets/images/upload/distributors/Amuse.png"
import AwalIcon from "../../../assets/images/upload/distributors/awal.png"
import CinqmusicIcon from "../../../assets/images/upload/distributors/cinqmusic.png"
import ConcordIcon from "../../../assets/images/upload/distributors/concord.jpeg"
import InterstellarIcon from "../../../assets/images/upload/distributors/interstellar.png"
import CiInfoIcon from "../../../assets/images/upload/distributors/ci-info.png"
import CdBabyIcon from "../../../assets/images/upload/distributors/cdbaby.svg"
import DittoIcon from "../../../assets/images/upload/distributors/ditto.png"
import FamilyInMusicIcon from "../../../assets/images/upload/distributors/familyinmusic.png"
import SongTrustIcon from "../../../assets/images/upload/distributors/song_trust.jpeg"
import TooLostIcon from "../../../assets/images/upload/distributors/too_lost.png"
import UnitedMastersIcon from "../../../assets/images/upload/distributors/united_masters.png"
import VeniceIcon from "../../../assets/images/upload/distributors/venice.png"
import IntegralIcon from "../../../assets/images/upload/distributors/integral.svg"
import MusicGateawayIcon from "../../../assets/images/upload/distributors/music-gateway.png"
import SounDropIcon from "../../../assets/images/upload/distributors/soundrop.jpeg"
import UtopiaMusicIcon from "../../../assets/images/upload/distributors/utopiamusic.png"
import LandrIcon from "../../../assets/images/upload/distributors/landr.png"
import JamendoIcon from "../../../assets/images/upload/distributors/jamendo.png"
import FreshTunesIcon from "../../../assets/images/upload/distributors/fresh_tunes.png"
import ZimbalamIcon from "../../../assets/images/upload/distributors/zimbalam.jpg"
import RouteNoteIcon from "../../../assets/images/upload/distributors/RouteNote.jpeg"
import HorusIcon from "../../../assets/images/upload/distributors/horus.jpeg"
import OrfiumIcon from "../../../assets/images/upload/distributors/orfium.png"
import IMusicianIcon from "../../../assets/images/upload/distributors/iMusician.jpeg"
import TunedlyIcon from "../../../assets/images/upload/distributors/tunedly.jpeg"
import LabelGridIcon from "../../../assets/images/upload/distributors/label_grid.jpeg"
import ReverbNationIcon from "../../../assets/images/upload/distributors/ReverbNation.jpeg"
import EmuBandsIcon from "../../../assets/images/upload/distributors/EmuBands.jpeg"
import SongCastIcon from "../../../assets/images/upload/distributors/SongCast.jpeg"
import MusicInfoIcon from "../../../assets/images/upload/distributors/Musicinfo.png"
import OctiiveIcon from "../../../assets/images/upload/distributors/octive.svg"
import RecordUnionIcon from "../../../assets/images/upload/distributors/RecordUnion.jpeg"
import SongtradrIcon from "../../../assets/images/upload/distributors/Songtradr.jpeg"
import OffStepIcon from "../../../assets/images/upload/distributors/OFFstep.jpeg"
//@ts-ignore
import SoundOnIcon from "../../../assets/images/upload/SoundOn.ico"
//@ts-ignore
import OrchardGuideline from "../../../assets/instructions/The-Orchard.pdf"
//@ts-ignore
import IngroovesGuideline from "../../../assets/instructions/ingrooves.pdf"
//@ts-ignore
import OneRPMGuideline from "../../../assets/instructions/OneRPM.pdf"
//@ts-ignore
import DistroKidGuideline from "../../../assets/instructions/DistroKid.pdf"
//@ts-ignore
import AWALGuideline from "../../../assets/instructions/AWAL.pdf"
//@ts-ignore
import CDBabyGuideline from "../../../assets/instructions/CDBaby.pdf"
//@ts-ignore
import DittoGuideline from "../../../assets/instructions/Ditto.pdf"
//@ts-ignore
import HorusGuideline from "../../../assets/instructions/Horus.pdf"
//@ts-ignore
import RoutenoteGuideline from "../../../assets/instructions/Routenote.pdf"
//@ts-ignore
import STEMGuideline from "../../../assets/instructions/STEM.pdf"
//@ts-ignore
import SymphonicGuideline from "../../../assets/instructions/Symphonic.pdf"
//@ts-ignore
import TooLostGuideline from "../../../assets/instructions/TooLost.pdf"
//@ts-ignore
import TunecoreGuideline from "../../../assets/instructions/Tunecore.pdf"
//@ts-ignore
import UnitedMastersGuideline from "../../../assets/instructions/UnitedMasters.pdf"
//@ts-ignore
import LANDRGuideline from "../../../assets/instructions/LANDR.pdf"

export interface Distributor {
  distributorType: string
  icon: string
  text: string
  isOther: boolean,
  guidelinePdf?: string
}

export const DISTRIBUTOR_LIST: Distributor[] = [
  { distributorType: DistributorTypes.ORCHARD, icon: OrchardIcon, text: "The Orchard", isOther: false, guidelinePdf: OrchardGuideline },
  { distributorType: DistributorTypes.INGROOVES, icon: IngroveesIcon, text: "Ingrooves", isOther: false, guidelinePdf: IngroovesGuideline },
  { distributorType: DistributorTypes.ONERPM, icon: OneRpmIcon, text: "ONErpm", isOther: false, guidelinePdf: OneRPMGuideline },
  { distributorType: DistributorTypes.SOUND_ON, icon: SoundOnIcon, text: "SoundOn", isOther: false },
  { distributorType: DistributorTypes.DISTRO_KID, icon: DistrokidIcon, text: "Distrokid", isOther: false, guidelinePdf: DistroKidGuideline },
  { distributorType: DistributorTypes.BELIEVE, icon: BelieveIcon, text: "Believe", isOther: false },
  { distributorType: DistributorTypes.TUNE_CORE, icon: TuneCoreIcon, text: "TuneCore", isOther: false, guidelinePdf: TunecoreGuideline },
  { distributorType: DistributorTypes.FUGA, icon: FugaIcon, text: "Fuga", isOther: true },
  { distributorType: DistributorTypes.IDOL, icon: IdolIcon, text: "iDOl", isOther: true },
  { distributorType: DistributorTypes.REVELATOR, icon: RevelatorIcon, text: "Revelator", isOther: true },
  { distributorType: DistributorTypes.STEM, icon: StemInIcon, text: "Stem", isOther: true, guidelinePdf: STEMGuideline },
  { distributorType: DistributorTypes.INTERSTELLAR, icon: InterstellarIcon, text: "Interstellar Music", isOther: true },
  { distributorType: DistributorTypes.SYMPHONIC, icon: SymphonicIcon, text: "Symphonic", isOther: true, guidelinePdf: SymphonicGuideline },
  { distributorType: DistributorTypes.ALTAFONTE, icon: AltafonteIcon, text: "Altafonte", isOther: true },
  { distributorType: DistributorTypes.AMUSE, icon: AmuseIcon, text: "Amuse", isOther: true },
  { distributorType: DistributorTypes.AWAL, icon: AwalIcon, text: "AWAL", isOther: true, guidelinePdf: AWALGuideline },
  { distributorType: DistributorTypes.CINQ_MUSIC, icon: CinqmusicIcon, text: "Cinq Music", isOther: true },
  { distributorType: DistributorTypes.CONCORD, icon: ConcordIcon, text: "Concord", isOther: true },
  { distributorType: DistributorTypes.CI_INFO, icon: CiInfoIcon, text: "Ci Info (Consolidated Independent)", isOther: true },
  { distributorType: DistributorTypes.CDBABY, icon: CdBabyIcon, text: "CD Baby", isOther: true, guidelinePdf: CDBabyGuideline },
  { distributorType: DistributorTypes.DITTO, icon: DittoIcon, text: "Ditto", isOther: true, guidelinePdf: DittoGuideline },
  { distributorType: DistributorTypes.FAMILY_IN_MUSIC, icon: FamilyInMusicIcon, text: "Family in Music", isOther: true },
  { distributorType: DistributorTypes.SONG_TRUST, icon: SongTrustIcon, text: "Song Trust", isOther: true },
  { distributorType: DistributorTypes.TOO_LOST, icon: TooLostIcon, text: "Too Lost", isOther: true, guidelinePdf: TooLostGuideline },
  { distributorType: DistributorTypes.UNITED_MASTERS, icon: UnitedMastersIcon, text: "United Masters", isOther: true, guidelinePdf: UnitedMastersGuideline },
  { distributorType: DistributorTypes.VENICE_MUSIC, icon: VeniceIcon, text: "Venice Music", isOther: true },
  { distributorType: DistributorTypes.INTEGRAL_MUSIC, icon: IntegralIcon, text: "Integral Music", isOther: true },
  { distributorType: DistributorTypes.MUSIC_GATEWAY, icon: MusicGateawayIcon, text: "Music Gateway", isOther: true },
  { distributorType: DistributorTypes.SOUNDROP, icon: SounDropIcon, text: "Soundrop", isOther: true },
  { distributorType: DistributorTypes.UTOPIA_MUSIC, icon: UtopiaMusicIcon, text: "Utopia Music", isOther: true },
  { distributorType: DistributorTypes.LANDR, icon: LandrIcon, text: "LANDR", isOther: true, guidelinePdf: LANDRGuideline },
  { distributorType: DistributorTypes.JAMENDO, icon: JamendoIcon, text: "Jamendo", isOther: true },
  { distributorType: DistributorTypes.FRESH_TUNES, icon: FreshTunesIcon, text: "Fresh Tunes", isOther: true },
  { distributorType: DistributorTypes.ZIMBALAM, icon: ZimbalamIcon, text: "Zimbalam", isOther: true },
  { distributorType: DistributorTypes.ROUTE_NOTE, icon: RouteNoteIcon, text: "Route Note", isOther: true, guidelinePdf: RoutenoteGuideline },
  { distributorType: DistributorTypes.HORUS_MUSIC, icon: HorusIcon, text: "Horus Music", isOther: true, guidelinePdf: HorusGuideline },
  { distributorType: DistributorTypes.ORFIUM, icon: OrfiumIcon, text: "Orfium", isOther: true },
  { distributorType: DistributorTypes.I_MUSICIAN, icon: IMusicianIcon, text: "iMusician", isOther: true },
  { distributorType: DistributorTypes.TUNEDLY, icon: TunedlyIcon, text: "Tunedly", isOther: true },
  { distributorType: DistributorTypes.LABEL_GRID, icon: LabelGridIcon, text: "Label Grid", isOther: true },
  { distributorType: DistributorTypes.REVERB_NATION, icon: ReverbNationIcon, text: "Reverb Nation", isOther: true },
  { distributorType: DistributorTypes.EMU_BANDS, icon: EmuBandsIcon, text: "Emu Bands", isOther: true },
  { distributorType: DistributorTypes.SONG_CAST, icon: SongCastIcon, text: "Song Cast", isOther: true },
  { distributorType: DistributorTypes.MUSIC_INFO, icon: MusicInfoIcon, text: "Music Info", isOther: true },
  { distributorType: DistributorTypes.OCTIIVE, icon: OctiiveIcon, text: "Octiive", isOther: true },
  { distributorType: DistributorTypes.RECORD_UNION, icon: RecordUnionIcon, text: "Record Union", isOther: true },
  { distributorType: DistributorTypes.SONGTRADR, icon: SongtradrIcon, text: "Songtradr", isOther: true },
  { distributorType: DistributorTypes.OFF_STEP, icon: OffStepIcon, text: "OFFstep", isOther: true }
]

export const countryOptions = [
  { label: "Afghanistan", value: 93 },
  { label: "Albania", value: 355 },
  { label: "Algeria", value: 213 },
  { label: "Andorra", value: 376 },
  { label: "Angola", value: 244 },
  { label: "Antigua and Barbuda", value: 1 },
  { label: "Argentina", value: 54 },
  { label: "Armenia", value: 374 },
  { label: "Australia", value: 61 },
  { label: "Austria", value: 43 },
  { label: "Azerbaijan", value: 994 },
  { label: "Bahamas", value: 1 },
  { label: "Bahrain", value: 973 },
  { label: "Bangladesh", value: 880 },
  { label: "Barbados", value: 1 },
  { label: "Belarus", value: 375 },
  { label: "Belgium", value: 32 },
  { label: "Belize", value: 501 },
  { label: "Benin", value: 229 },
  { label: "Bhutan", value: 975 },
  { label: "Bolivia", value: 591 },
  { label: "Bosnia and Herzegovina", value: 387 },
  { label: "Botswana", value: 267 },
  { label: "Brazil", value: 55 },
  { label: "Brunei", value: 673 },
  { label: "Bulgaria", value: 359 },
  { label: "Burkina Faso", value: 226 },
  { label: "Burundi", value: 257 },
  { label: "Cabo Verde", value: 238 },
  { label: "Cambodia", value: 855 },
  { label: "Cameroon", value: 237 },
  { label: "Canada", value: 1 },
  { label: "Central African Republic", value: 236 },
  { label: "Chad", value: 235 },
  { label: "Chile", value: 56 },
  { label: "China", value: 86 },
  { label: "Colombia", value: 57 },
  { label: "Comoros", value: 269 },
  { label: "Congo", value: 242 },
  { label: "Costa Rica", value: 506 },
  { label: "Croatia", value: 385 },
  { label: "Cuba", value: 53 },
  { label: "Cyprus", value: 357 },
  { label: "Czech Republic", value: 420 },
  { label: "Denmark", value: 45 },
  { label: "Djibouti", value: 253 },
  { label: "Dominica", value: 1 },
  { label: "Dominican Republic", value: 1 },
  { label: "East Timor (Timor-Leste)", value: 670 },
  { label: "Ecuador", value: 593 },
  { label: "Egypt", value: 20 },
  { label: "El Salvador", value: 503 },
  { label: "Equatorial Guinea", value: 240 },
  { label: "Eritrea", value: 291 },
  { label: "Estonia", value: 372 },
  { label: "Eswatini", value: 268 },
  { label: "Ethiopia", value: 251 },
  { label: "Fiji", value: 679 },
  { label: "Finland", value: 358 },
  { label: "France", value: 33 },
  { label: "Gabon", value: 241 },
  { label: "Gambia", value: 220 },
  { label: "Georgia", value: 995 },
  { label: "Germany", value: 49 },
  { label: "Ghana", value: 233 },
  { label: "Greece", value: 30 },
  { label: "Grenada", value: 1 },
  { label: "Guatemala", value: 502 },
  { label: "Guinea", value: 224 },
  { label: "Guinea-Bissau", value: 245 },
  { label: "Guyana", value: 592 },
  { label: "Haiti", value: 509 },
  { label: "Honduras", value: 504 },
  { label: "Hungary", value: 36 },
  { label: "Iceland", value: 354 },
  { label: "India", value: 91 },
  { label: "Indonesia", value: 62 },
  { label: "Iran", value: 98 },
  { label: "Iraq", value: 964 },
  { label: "Ireland", value: 353 },
  { label: "Israel", value: 972 },
  { label: "Italy", value: 39 },
  { label: "Ivory Coast", value: 225 },
  { label: "Jamaica", value: 1 },
  { label: "Japan", value: 81 },
  { label: "Jordan", value: 962 },
  { label: "Kazakhstan", value: 7 },
  { label: "Kenya", value: 254 },
  { label: "Kiribati", value: 686 },
  { label: "Korea, North", value: 850 },
  { label: "Korea, South", value: 82 },
  { label: "Kosovo", value: 383 },
  { label: "Kuwait", value: 965 },
  { label: "Kyrgyzstan", value: 996 },
  { label: "Laos", value: 856 },
  { label: "Latvia", value: 371 },
  { label: "Lebanon", value: 961 },
  { label: "Lesotho", value: 266 },
  { label: "Liberia", value: 231 },
  { label: "Libya", value: 218 },
  { label: "Liechtenstein", value: 423 },
  { label: "Lithuania", value: 370 },
  { label: "Luxembourg", value: 352 },
  { label: "Madagascar", value: 261 },
  { label: "Malawi", value: 265 },
  { label: "Malaysia", value: 60 },
  { label: "Maldives", value: 960 },
  { label: "Mali", value: 223 },
  { label: "Malta", value: 356 },
  { label: "Marshall Islands", value: 692 },
  { label: "Mauritania", value: 222 },
  { label: "Mauritius", value: 230 },
  { label: "Mexico", value: 52 },
  { label: "Micronesia", value: 691 },
  { label: "Moldova", value: 373 },
  { label: "Monaco", value: 377 },
  { label: "Mongolia", value: 976 },
  { label: "Montenegro", value: 382 },
  { label: "Morocco", value: 212 },
  { label: "Mozambique", value: 258 },
  { label: "Myanmar (Burma)", value: 95 },
  { label: "Namibia", value: 264 },
  { label: "Nauru", value: 674 },
  { label: "Nepal", value: 977 },
  { label: "Netherlands", value: 31 },
  { label: "New Zealand", value: 64 },
  { label: "Nicaragua", value: 505 },
  { label: "Niger", value: 227 },
  { label: "Nigeria", value: 234 },
  { label: "North Macedonia", value: 389 },
  { label: "Norway", value: 47 },
  { label: "Oman", value: 968 },
  { label: "Pakistan", value: 92 },
  { label: "Palau", value: 680 },
  { label: "Panama", value: 507 },
  { label: "Papua New Guinea", value: 675 },
  { label: "Paraguay", value: 595 },
  { label: "Peru", value: 51 },
  { label: "Philippines", value: 63 },
  { label: "Poland", value: 48 },
  { label: "Portugal", value: 351 },
  { label: "Qatar", value: 974 },
  { label: "Romania", value: 40 },
  { label: "Russia", value: 7 },
  { label: "Rwanda", value: 250 },
  { label: "Saint Kitts and Nevis", value: 1 },
  { label: "Saint Lucia", value: 1 },
  { label: "Saint Vincent and the Grenadines", value: 1 },
  { label: "Samoa", value: 685 },
  { label: "San Marino", value: 378 },
  { label: "Sao Tome and Principe", value: 239 },
  { label: "Saudi Arabia", value: 966 },
  { label: "Senegal", value: 221 },
  { label: "Serbia", value: 381 },
  { label: "Seychelles", value: 248 },
  { label: "Sierra Leone", value: 232 },
  { label: "Singapore", value: 65 },
  { label: "Slovakia", value: 421 },
  { label: "Slovenia", value: 386 },
  { label: "Solomon Islands", value: 677 },
  { label: "Somalia", value: 252 },
  { label: "South Africa", value: 27 },
  { label: "South Sudan", value: 211 },
  { label: "Spain", value: 34 },
  { label: "Sri Lanka", value: 94 },
  { label: "Sudan", value: 249 },
  { label: "Suriname", value: 597 },
  { label: "Sweden", value: 46 },
  { label: "Switzerland", value: 41 },
  { label: "Syria", value: 963 },
  { label: "Taiwan", value: 886 },
  { label: "Tajikistan", value: 992 },
  { label: "Tanzania", value: 255 },
  { label: "Thailand", value: 66 },
  { label: "Togo", value: 228 },
  { label: "Tonga", value: 676 },
  { label: "Trinidad and Tobago", value: 1 },
  { label: "Tunisia", value: 216 },
  { label: "Turkey", value: 90 },
  { label: "Turkmenistan", value: 993 },
  { label: "Tuvalu", value: 688 },
  { label: "Uganda", value: 256 },
  { label: "Ukraine", value: 380 },
  { label: "United Arab Emirates", value: 971 },
  { label: "United Kingdom", value: 44 },
  { label: "United States", value: 1 }
]

export const lookingForOptions = [
  "DISTRIBUTION",
  "SPLITS & FINANCIAL TOOLS",
  "COLLABORATION",
  "FUNDING",
  "MARKETING & PR",
  "DRM & UGC MONETIZATION"
]

export enum ConfirmedOfferTypes {
  ADVANCE = "advance",
  BUYOUT = "buyout"
}

export enum RoyaltyReportTypes {
  INDIVIDUAL = "individual",
  LABEL = "label"
}

export enum UserRoles {
  SCOUTING_ADMIN = 1,
  SCOUTING_ACCESS = 2,
  BLACK_LIST_VALIDATOR = 3,
  OFFERS_ACCESS = 10,
  SIMENA_ACCESS = 20
}
